<template>
  <div :style="slidePosition" class="z-6 h-100" style="width:100%;">
    <div
      class="position-absolute  text-warning gradient-mask-20"
      style="top:37.5%; height:16%; left:5%; width:35%;transform:translateY(-50%)"
    >
      <div
        class="lns20m d-flex flex-nowrap align-items-end "
        style="height: 100%;width:200%;"
        ref="lns4"
      >
        <div
          v-for="(number, index) in parseInt(40 * this.aspectRatio)"
          :key="index"
          class="vertical-border border-danger"
          style="height: 100%;width:10%;border-right:solid 0.3vh;"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import anime from "animejs"
import { slidePositionPlain } from "@/utils/slidePositioning"
export default {
  props: ["globalUnits", "slideIndex"],
  computed: {
    slidePosition() {
      //console.log("SLIDES INDEX", this.slideIndex)
      return slidePositionPlain(this.globalUnits, this.slideIndex)
    },
    innerPosition() {
      return (
        (this.globalUnits - this.slideIndex.start) /
        (this.slideIndex.end - this.slideIndex.start)
      )
    },
    aspectRatio() {
      return window.screen.availWidth / window.screen.availHeight
    }
  },

  mounted() {
    this.startLoop()
  },
  methods: {
    startLoop() {
      anime({
        loop: true,
        targets: ".lns20m",
        translateX: ["0%", "-40%"],
        direction: "alternate",
        duration: 8000,
        easing: "easeInOutSine"
      })
    },
    gradientMask4(percentage) {
      //console.log("VOY AQUI EN LUINEA 4", percentage)
      let gradientMask4 = anime
        .timeline({
          autoplay: false,
          loop: false,
          endDelay: 0
        })
        .add({
          targets: ".gradient-mask-20",

          left: ["0%", "-30%"],
          duration: 1000,
          delay: 0,
          easing: "linear"
        })

      gradientMask4.seek(
        gradientMask4.duration * percentage <= gradientMask4.duration
          ? gradientMask4.duration * percentage
          : gradientMask4.duration * 0.5
      )
    },

    lns20m(percentage) {
      let lns20m = anime
        .timeline({
          autoplay: false,
          loop: false,
          endDelay: 0
        })

        .add({
          targets: ".lns20m",

          /* opacity: [100, 0], */

          translateX: ["20%", "-20%"],
          duration: 2000,
          delay: 2000,
          easing: "linear"
        })

      lns20m.seek(
        percentage <= 0.5
          ? lns20m.duration * (2 * percentage)
          : lns20m.duration * 1
      )
    }
  },
  watch: {
    innerPosition() {
      //this.lns4(this.innerPosition)
      this.lns20m(this.innerPosition)
    }
  }
}
</script>

<style>
.gradient-mask-20 {
  mask-image: linear-gradient(
    -90deg,
    transparent 0%,
    black 20%,
    black 80%,
    transparent 100%
  );
}
</style>
